<template>
  <b-modal
    visible
    scrollable
    centered
    :title="region?.id ? 'Редактирование региона' : 'Добавление региона'"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="region-form"
    >
      <b-form-group
        label="Название региона"
        label-class="required"
        :state="!errors.name"
        :invalid-feedback="errors.name && errors.name.join(', ')"
      >
        <b-form-input
          v-model.trim="region.name"
          type="text"
          placeholder="Введите название региона"
        />
      </b-form-group>

      <b-form-group
        label="Код региона"
        label-class="required"
        :state="!errors.code"
        :invalid-feedback="errors.code && errors.code.join(', ')"
      >
        <b-form-input
          v-model.trim="region.code"
          type="text"
          placeholder="Введите код региона"
        />
      </b-form-group>

      <b-form-group
        label="Конституционный код"
        label-class="required"
        :state="!errors.constitutionCode"
        :invalid-feedback="errors.constitutionCode && errors.constitutionCode.join(', ')"
      >
        <b-form-input
          v-model.trim="region.constitutionCode"
          type="text"
          placeholder="Введите конституционный код"
        />
      </b-form-group>

      <b-form-group
        label="Таймзона"
        label-class="required"
        :state="!errors.timezone"
        :invalid-feedback="errors.timezone && errors.timezone.join(', ')"
      >
        <b-form-input
          v-model.trim="region.timezone"
          type="text"
          placeholder="Введите таймзону"
        />
      </b-form-group>

      <b-form-group
        label="ОКАТО"
        label-class="required"
        :state="!errors.okatoCode"
        :invalid-feedback="errors.okatoCode && errors.okatoCode.join(', ')"
      >
        <b-form-input
          v-model.trim="region.okatoCode"
          type="text"
          placeholder="Введите ОКАТО"
        />
      </b-form-group>

      <div class="d-flex">
        <b-form-checkbox
          v-model="region.isTmk"
        >
          ТМК
        </b-form-checkbox>

        <b-form-checkbox
          v-model="region.isDismonitoring"
          class="ml-4"
        >
          Дисмониторинг
        </b-form-checkbox>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        :disabled="isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';

import { locationService } from '@/services';
import {
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';
import { checkNotEmptyStringSchema } from '@/validation/zod/notempty.validation.js';

export default {
  name: 'RegionEditModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    regionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSaving: false,
      isOpen: true,
      region: {
        code: '',
        constitutionCode: '',
        isDismonitoring: false,
        isTmk: false,
        name: '',
        okatoCode: '',
        timezone: '',
        isActive: true,
      },
      errors: {},
    };
  },
  created() {
    if (this.regionData) {
      const region = JSON.parse(JSON.stringify(this.regionData));
      const result = Object.fromEntries(
        Object.entries(region).map(([key, value]) => [
          key,
          typeof value === 'number' ? String(value) : value,
        ]),
      );
      this.region = { ...result };
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onClickSave() {
      this.errors = {};
      const schema = checkNotEmptyStringSchema([
        'name',
        'code',
        'constitutionCode',
        'timezone',
        'okatoCode',
      ]);
      const result = schema.safeParse(this.region);

      if (!result.success) {
        result.error.errors.forEach((error) => {
          this.$set(this.errors, error.path[0], [error.message]);
        });
        return;
      }

      this.isSaving = true;

      try {
        if (this.region.id) {
          // ! check it
          await this.regionHandler(this.region, 'update');
        } else {
          await this.regionHandler(this.region, 'create');
        }

        Bus.$emit('regions:update');
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    async regionHandler(obj, value) {
      try {
        if (value === 'create') {
          const data = await locationService.createRegion(obj);
          showSuccessCustomMessage('Регион успешно создан!');
          return data;
        }
        if (value === 'update') {
          const data = locationService.updateRegion(obj);
          showSuccessCustomMessage('Регион успешно изменен!');
          return data;
        }
      } catch (err) {
        if (value === 'create') {
          console.warn(err);
          showErrorCustomMessage('Не удалось создать регион');
          throw new Error(err);
        }
        console.warn(err);
        showErrorCustomMessage('Не удалось изменить регион');
        throw new Error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.region-id {
  font-size: 14px;
  line-height: 14px;
  color: #908F8D;
  margin-bottom: 15px;
}
.region-form {
  width: 100%;

  .crm-form-field + .crm-form-field {
    margin-top: 15px;
  }
}
.crm-wrapper-buttons {
  margin-top: 50px;
}

::v-deep .validation-errors {
  top: calc(100% + -1.5px);
}
::placeholder {
  font-size: 0.8em;
}
</style>
